.link-builder-toggle {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}
.link-builder-wrapper {
    position: relative;
}
.link-builder {
    position: absolute;
    background: #eee;
    padding: 1rem;
    z-index: 9999;
    border: 1px solid #e3e3e3;
    width: 100%;
}
.link-builder select {
    width: 48%;
    margin: .5rem 1%;
    border: 1px solid #e3e3e3;
}
.link-builder button {
    margin: .5rem auto;
    display: inherit;
    border: 1px solid #e3e3e3;
}
form .c-wall-post .link-builder-toggle {
    right: 5rem;
}

.j-wall-status-form-block .c-area-group1 .link-builder-toggle{
    right: 11.5rem !important;
}
.selected.color6 {
    background: #6196c3 !important;
    color: #fff !important;
}
.selected.color7 {
    background: #fea841 !important;
    color: #fff !important;
}
.selected.color5 {
    background: #49ba93 !important;
    color: #fff !important;
}
#print-all {
    position: fixed;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4rem;
    height: 4rem;
    background: #00b9f7;
    color: #fff;
    font-size: 2rem;
    text-align: center;
    line-height: 4rem;
}

.c-verse-icon3:after {
    content: 'Click to add to print list';
    display: block;
    font-size: 9px;
    position: absolute;
    font-family: sans-serif;
    color: #777;
    text-align: center;
    line-height: 9px;
}