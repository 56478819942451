.reader {
    @media (--xsmall) {
        & .mt12 {
            margin-top: 0 !important;
        }
        & .mb1 {
            margin-bottom: 30px !important;
        }
        & .mb1-r.mb1 {
            margin-bottom: 0px !important;
        }
        & .mt14 {
            margin-top: 30px !important;
        }
        & .in-inner-container {
            padding: 0 !important;
        }
    }
}

.highlight-actions.imobile{
    transform: rotate(180deg);
}

.highlight-actions.imobile i{
    transform: rotate(180deg);
}

.btn-top-label1 {
    line-height: 1.29rem;
    text-transform: uppercase;
    text-align: center;
}
.btn-sub-label1 {
    color: #a4a4a4;
    font-family: ns_widebook;
    line-height: 1.29rem;
    text-align: center;
    text-transform: capitalize;
}
.btn-min-w {
    min-width: 288px;
}
@media (--medium) {
    .btn-top-label1 {
        font-size: 0.79rem;
        line-height: 0.9rem;
    }
    .btn-sub-label1 {
        font-size: 0.72rem;
        line-height: 0.9rem;
    }
    .btn-min-w {
        min-width: 0;
    }
    .reader-res-bott-panel-l {
        width: 50%;
        padding-left: 15px;
        & .btn-min-w:first-child {
            min-width: 100%;
        }
        & .btn2, & .btn1 {
            float: right !important;
            margin-bottom: 5px;
            padding: 10px 5px;
        }
        & .mr5 {
            margin-right: 3px;
        }
    }
    .reader-res-bott-panel-r {
        width: 50%;
        padding-right: 15px;
        & .btn-min-w:first-child {
            min-width: 100%;
        }
        & .btn2, & .btn1 {
            margin-bottom: 5px;
            padding: 10px 5px;
        }
        & .ml1 {
            margin-left: 3px;
        }
    }
    .btn-book-resp-l {
        width: 80%;
        background: #404040;
        color: #ffffff;
        padding: 5px !important;
        & .btn-top-label1 {
            color: #ffffff;
        }
        & .btn-sub-label1 {
            color: #ffffff;
        }
    }
    .btn-book-resp-r {
        width: 80%;
        background: #00b9f7;
        color: #ffffff;
        padding: 5px !important;
        & .btn-top-label1 {
            color: #ffffff;
        }
        & .btn-sub-label1 {
            color: #ffffff;
        }
    }
    .btn-book-resp-l:hover,
    .btn-book-resp-r:hover {
        background: #79858f;
        border-color: #79858f;
    }
}
.btn1:hover .btn-sub-label1,
.btn2:hover .btn-sub-label1 {
    color: white;
}
.c-sharing {
    position: absolute;
    top: 0;
    left: 0;
}
.icon-panel {
    position: absolute;
    right: 0;
    top: 0;
}
ul.icon-panel li {
    display: inline-block;
    list-style: none;
    padding: 0 8px;
}
ul.icon-panel li:last-child {
    display: inline-block;
    list-style: none;
    padding-right: 0;
}
ul.icon-panel li a {
    text-decoration: none !important;
}
.top-vertical1 {
    top: 50%;
    margin-top: -13px;
}
@media (--xsmall) {
    .top-vertical1 {
        margin-top: 60px;
    }
}

.verse-text .word-definition.symbolism.withVideo{
    border-bottom: 2px solid #00b9f7;
}

.verse-text .fa-comment,.verse-text .fa-film, .verse-text .fa-video{
  position: relative;
  left: -5px;
  top: -11px;
  color: #00b9f7 !important;
  font-size: 1rem;
  margin-right: -10px;
}

@media (--xsmall) {
    .verse-text .fa-comment,.verse-text .fa-film, .verse-text .fa-video {
        top: -8px;
        font-size: 0.5rem;
        margin-right: -7px;
    }
}

.col-xs-6 .verse-text, .col-xs-4 .verse-text {
    font-size: 1.14rem;
}
.col-xs-6  .verse-text b a, .col-xs-4 .verse-text b a {
    font-size: 1.14rem;
}
.c-reader-content .row .col-md-6:last-child .inner-pad1 {
    padding-left: 40px;
}
.verse-text b {
    font-weight: normal !important;
}
.verse-text b a{
    margin-left: 10px;
    color: #282828;
    font-size: 1.29rem;
    font-family: work_sansbold;
    text-decoration: none !important;
    font-weight: normal !important;
}
.verse-text:first-child b a{
    margin-left: 0;
}
.version-title {
    color: #313131;
    font-family: ns_widenormal;
    font-size: 1.43rem;
    padding-bottom: 30px;
    margin: 0;
    text-transform: uppercase;
    padding-right: 30px;
}
.verse-date {
    float: right;
    color: #cacaca;
    font-size: 1.14rem;
    font-family: work_sansregular;
}
@media(--xsmall){
    .verse-date {
        font-size: 1rem;
    }
}
.c-popup-compare {
    width: 430px;
    text-align: left;
}
.comp-bord1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 1px;
    border-left: 1px solid #f3f3f3;
}
.comp-bord2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 33.333333%;
    width: 1px;
    border-left: 1px solid #f3f3f3;
}
.comp-bord3 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 66.6666666%;
    width: 1px;
    border-left: 1px solid #f3f3f3;
}
.j-reader-actions {
    background: #00b9f7;
    padding: 20px 0 15px;
    -webkit-box-shadow: 0 0 15px 5px rgba(100,100,100,0.2);
    box-shadow: 0 0 15px 5px rgba(100,100,100,0.2);
}

.highlight-actions{
    background: white;
    border: 1px solid #00b9f7;
}

.highlight-actions .popup-arrow2{
    background: white;
    border: 1px solid #00b9f7;
    border-left: 0;
    border-top: 0;
}

.j-reader-actions.highlight-actions i.fa-eraser{
    color: #00b9f7 !important;
}

.j-reader-actions.highlight-actions i.fa-eraser:hover{
    color: #000 !important;
}

@media (--xsmall) {
    .j-reader-actions {
        padding: 15px 0 8px;
        margin-left:30px;
        margin-top:17px;
    }
}
.btn-reader {
    display:inline-block;
    color:white;
    padding: 0 20px;
    vertical-align: top;
    text-align: center;
}
@media (--xsmall) {
    .btn-reader {
        padding: 0 12px;
    }
}
.btn-reader:hover,
.btn-reader i:hover{
    text-decoration: none !important;
    color: #101010 !important;
}
.btn-reader i {
    font-size: 1.71rem;
    display: block;
}
@media (--xsmall) {
    .btn-reader i {
        font-size: 1.4rem;
    }
}
.h3-related {
    color: #313131;
    font-family: ns_widenormal;
    font-size: 1.43rem;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
}
@media(--xsmall){
    .h3-related {
        font-size: 1.16rem;
    }
}
@media(--small){
    .h3-related {
        font-size: 1rem;
        padding-right: 30px;
    }
}
.bord1 {
    border-bottom: 1px solid #f3f3f3;
}
.ins-count {
    color: #cacaca;
}
.cu-staroutlined {
    color: #00b9f7;
    font-size: 1.71rem;
    margin-right: 8px;
    vertical-align: top;
}
@media(--xsmall){
    .cu-staroutlined {
        font-size: 1.16rem;
    }
}
@media(--small){
    .h3-related{
        & .cu-staroutlined {
            font-size: 1rem;
        }
    }
}
.related-records .date-order-btn {
    position: absolute;
    top: 38px;
    right: 38px;
    color: #00b9f7;
}
@media(--xsmall){
    .related-records .date-order-btn {
        top: 15px;
        right: 15px;
    }
}
.related-records .date-order-btn a span {
    color: #00b9f7;
}
.related-records .date-order-btn a {
    text-decoration: none;
}
.related-records .related-item .item-body .verse-block {
    color: #858585;
    font-size: 1.14rem;
    font-family: work_sansregular;
    font-style: italic;
    position: relative;
    padding: 10px 0;
    line-height: 1.71rem;
}
@media(--xsmall){
    .related-records .related-item .item-body .verse-block {
        font-size: 1rem;
        line-height: 1.2rem;
    }
}
.verse-block-bott {
    display: block;
    padding-left: 26px;
    padding-top: 10px;
    position: relative;
    color: #535353;
    font-size: 1.14rem;
    font-family: work_sansmedium;
    line-height: 1.71rem;
}
@media(--xsmall){
    .verse-block-bott {
        font-size: 1rem;
    }
}
.verse-icon {
    position: absolute;
    display: block;
    top: 11px;
    left: 0;
    color: #535353;
    font-size: 1.36rem;
    font-family: "bs" !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-decoration: none !important;
    text-transform: none;
}
.verse-icon2 {
    position: absolute;
    display: block;
    background: #79858f;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    -webkit-border-radius: 50px;
    top: 20px;
    left: 74px;
    color: #ffffff;
    font-size: 0.86rem;
    font-family: "bs" !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1.43rem;
    text-align: center;
    text-decoration: none !important;
    text-transform: none;
    border: 3px solid #ffffff;
}
@media(--xsmall){
    .verse-icon2 {
        width: 25px;
        height: 25px;
        left: 49px;
        border-width: 2px;
    }
}
.c-verse-icon3 {
    position: absolute;
    display: block;
    top: 30px;
    left: 30px;
    color: #535353;
    font-family: "bs" !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    text-decoration: none !important;
    text-transform: none;
    text-align: center;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    border: 1px solid;
    font-size: 1.78rem;
    height: 60px;
    width: 60px;
    line-height: 4.29rem;

}
.a-study {
    text-decoration: none !important;
    vertical-align: top;
    display: inline-block;
}
.study-list {
    display: block;
    background: white;
    -webkit-box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
    box-shadow: 0 0 30px 2px rgba(100,100,100,0.1);
    position: relative;
    overflow: hidden;
    padding: 0;
}
.study-list > li {
    display: block;
    border-bottom: 1px solid #e3e3e3;
    position: relative;
    padding: 25px 30px;
}
.reader.my-study-verse {
    & .study-list > li {
        overflow: hidden;
    }
}
.study-list > li:hover {
    background: #f3f6f9;
}
.study-list > li:hover .journey-text-link {
    text-decoration: underline;
    color: #00b9f7 !important;
}
.study-list > li p {
    color: #535353;
    font-family: work_sansregular;
    font-size: 1.07rem;
}
.c-study-bottom {
    overflow: hidden;
    margin-top: 20px;
}
/*.reader.my-study-verse {
    & .c-study-bottom {
        float: right;
    }
}*/
ul.icons-list {
    display: block;
    padding-left: 10px;
}
ul.icons-list li {
    display: inline-block;
    color: #00b9f7;
}
ul.icons-list li a {
    display: inline-block;
    margin-left: 20px;
}
ul.icons-list li i {
    display: inline-block;
    color: #00b9f7 !important;
    font-size: 1.29rem !important;
    vertical-align: sub;
}
.compare-list {
    padding: 0;
    list-style: none;
    margin: 10px 0 10px 0px;
}
.compare-list li{
    padding: 5px;
}
.live-search-list {
    padding: 0;
    margin: 10px 0 0 -5px;
}
.live-search-list li{
    cursor: pointer;
    list-style: none;
    padding: 5px;
}
.live-search-list li:hover{
    color: #fff;
    background-color: #00b9f7;
}
.live-search-box{
    width: 100%;
    height: 40px;
    padding: 5px 12px;
}

.autocomplete {
    padding: 0;
    margin: 10px 0 0 0;
    text-align: left;
}
.autocomplete li{
    cursor: pointer;
    list-style: none;
    padding: 5px;
    border-bottom: 1px dotted #ccc;
}
.autocomplete li:hover{
    color: #fff;
    background-color: #00b9f7;
}
.c-popup-autocomplete{
    top: 35px;
    padding: 10px 5px;
    width: 100% !important;
}

@media (--xsmall){
    .live-search-box {
        font-size: 0.71rem;
    }
}
.btn-reset-compare{
    top: 20px;
    right: 20px;
}
@media(--xsmall){
    .btn-reset-compare{
        top: 5px;
        right: 5px;
    }
}
.reader-text .j-diff-block:last-child .btn-reset-compare{
    right: 35px;
}
@media(--xsmall){
    .reader-text .j-diff-block:last-child .btn-reset-compare{
        right: 17px;
    }
}
.legend-block {
    margin-bottom: 10px;
    padding: 0 30px;
}
.legend-item {
    display: inline-block;
    margin-left: 20px;
}
.legend-icon{
    display: inline-block;
    border-radius: 50%;
    width: 11px;
    height: 11px;
}
.legend-del-color{
    background:#fe9921;
}
.legend-ins-color{
    background: #36A741;
}
.legend-text{
    display: inline-block;
    vertical-align: top;
    margin-left: 5px;
}
h3.strongs-header {
    text-transform: lowercase;
    line-height: 2.57rem;
    text-align: center;
}
h3.strongs-subheader {
    margin: 30px 0 5px;
}
.j-reader-actions i,
.j-wall-item .item-header i.verse-icon2{
    color: #fff !important;
}
.reader-banner {
    background: url(/images/banner.png) center center no-repeat;
    height: 404px;
    color: #ffffff;
    padding-top: 95px;
    letter-spacing: 2px;
}
@media (--xsmall) {
    .reader-banner {
        height: auto;
        padding: 30px 30px;
        & h2.h2-2 {
            font-size: 1.2rem;
        }
    }
}
a.banner-btn{
    font-family: ns_widedemibold !important;
    font-size: 1.29rem !important;
    padding: 25px 50px !important;
    letter-spacing: 1px;
    margin-top: 44px;
}
@media (--xsmall) {
    a.banner-btn {
        padding: 15px 50px !important;
        margin-top: 30px;
    }
}
del {
    color: #fe9921;
    text-decoration: none;
    font-style: italic;
}
del:before {
    content: "(";
}
del:after {
    content: ") ";
}
ins {
    color: #36A741 ;
    text-decoration: none;
    font-weight: bold;
}
.verse-text b {
    font-weight: normal !important;
}
.verse-text b a {
    color: #282828;
    font-size: 1.29rem;
    font-family: work_sansbold;
    text-decoration: none !important;
    font-weight: normal !important;
}
@media (--xsmall) {
    .verse-text b a {
        font-size: 0.91rem;
        line-height: 1.46rem;
    }
}
.verse-text:hover, .clicked{
    color: #00b9f7 !important;
    cursor: pointer;
    border-bottom: 1px solid #00b9f7;
}

.verse-text:hover b a, .clicked b a{
    color: #00b9f7 !important;
}

.c-verse-text-top .verse-text:hover {
    color: #010101 !important;
    cursor: default;
    border-bottom: none;
}
.glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
}
.def-highlight, span.word-definition:hover{
    font-family: work_sansmedium !important;
}

span.word-definition mark:hover{
    font-family: work_sansbold !important;
}

.word-definition{
    border-bottom: 1px dotted;
}
.word-definition .word-definition{
    border: none;
}
.spliter1 {
    display: inline-block;
    height: 24px;
    width: 1px;
    background: white;
}
.lex-content {
    background-color: #F7F7F7;
    border: 1px solid #EEEEEE;
    border-radius: 2px;
    padding: 15px 20px;
    margin: 30px 0;
    position: relative;
    color: initial;
}
@media(--xsmall){
    .lex-content {
        margin: 30px -30px;
        background-color: #e3e3e3;
    }
    .compare-mode .lex-content {
        margin: 30px -26px -20px -11px;
        font-size: 0.9rem;
    }
}
.lex-content:hover {
    color: #535353;
}
.lex-content i.bs-lexicon {
    color: #00b9f7;
}
.lex-content b {
    font-family: work_sansbold;
}
.btn-reset:hover {
    background: #00b9f7;
    color: white;
    text-decoration: none !important;
}
.lex-content .btn-reset{
    height: 28px;
    width: 28px;
    top: 12px;
    right: 20px;
}
.btn-related-rec-resp {
    display: none !important;
}
@media (--xsmall) {
    .resp-offset {
        padding: 0 15px 5px;
    }
    .lex-content .btn-reset{
        height: 26px;
        width: 26px;
        background: #00b9f7;
        color: white;
        text-decoration: none !important;
        & :hover{
            background: white !important;
            color: #00b9f7;
            text-decoration: none !important;
        }
    }
    .resp-line {
        display: block;
        border-bottom: 1px solid #a1a1a1;
        margin: 15px;
    }
    .btn-related-rec-resp {
        display: inline-block !important;
    }

}
a.my-bookmarks {
    float: right;
    text-transform: uppercase;
    font-family: ns_widedemibold;
    font-size: 0.93rem;
}
a.my-bookmarks i {
    font-size: 1.79rem;
    color: #00b9f7;
    vertical-align: middle;
    margin-right: 5px;
}
a.my-bookmarks:hover{
    text-decoration: none;
    color: #00b9f7;
}
.my-bookmarks-list .bookmark-item{
    line-height: 30px;
}
@media(--xsmall) {
    a.my-bookmarks{
        margin-right: 15px;
        font-size: 0.6rem;
    }
    a.my-bookmarks i {
        font-size: 1.28rem;
    }
}

mark {
    padding:0;
    /*font-family: work_sansmedium;*/
    background: none;
}

.j-highlight-text a{
    background: white !important;
}

.j-highlight-text.j-green i{
    color: #36A741 !important;
}

.j-highlight-text.j-green i:hover{
    color: #000 !important;
}

.j-highlight-text.j-yellow i{
    color: #fe9921 !important;
}

.j-highlight-text.j-yellow i:hover{
    color: #000 !important;
}

.j-bible-text .j-green{
    background: #36A741 !important;
    color: white !important;
}

.j-bible-text .j-yellow{
    background: #fe9921 !important;
    color: white !important;
}

.j-highlight-text.j-green{
    padding-right: 20px;
}

.j-highlight-text.j-yellow,.btn-remove-highlighted-text{
    padding-left: 0px;
    padding-right: 20px;
}
@media(--xsmall){
    .j-mobile-rel-rec {
        padding: 0;
        & .c-reader-content2 {
            left: 0;
            right: 0;
            bottom: 10px;
        }
        & .popup-arrow {
            background: white;
            z-index: 9;
            border: none;
            right: 129px;
        }
    }
}
@media(--not-xsmall){
    .j-mobile-rel-rec {
        display: none !important;
    }
}
@media(--xsmall){
    .j-bible-text.col-xs-4 {
        & h4.version-title {
            font-size: 0.8rem;
            line-height: 0.9rem;
            padding-bottom: 10px;
            padding-right: 0;
        }
        & .inner-pad1 {
            padding: 30px 10px 10px;
        }
        &  .verse-text {
            font-size: 0.8rem;
            line-height: 0.9rem;
        }
        &  .verse-text b a {
            font-size: 0.8rem;
            line-height: 0.9rem;
        }
        & .btn-reset {
            font-size: 2rem;
        }
    }
    .j-bible-text.col-xs-6 {
        & h4.version-title {
            font-size: 0.8rem;
            line-height: 0.9rem;
            padding-bottom: 10px;
            padding-right: 0;
        }
        & .inner-pad1 {
            padding: 30px 10px 10px;
        }
        &  .verse-text {
            font-size: 0.8rem;
            line-height: 0.9rem;
        }
        &  .verse-text b a {
            font-size: 0.8rem;
            line-height: 0.9rem;
        }
        & .btn-reset {
            font-size: 2rem;
        }
    }
}

.related-item .j-item-body:hover{
    cursor: pointer;
}

@media(--xsmall){
    .reader.search .c-white-content.old-testament{
        margin-bottom: 20px;
    }
}

.btn-prev{
    margin-right: 30px;
}
@media(--xsmall){
    .btn-prev{
        margin: auto auto 10px;
    }
}
.def-highlight {
    text-transform: uppercase;
}
.lex-verse-part-icon {
    float: left;
    margin-right: 8px;
}
.lex-verse-part-first {
    float: left;
}
.lex-verse-part {
    float: left;
}
.hr-resp {
    display: none;
}
@media(--xsmall){
    .hr-resp {
        display: block;
    }
}
.row-lex {
    overflow: hidden;
    display: inline-block;
    margin-right: 40px;
}
.col-lex-title {
    float: left;
    padding-right: 10px;
    padding-top: 1px;
}
.col-lex-val {
    float: left;
}

@media(--xsmall){
    .col-lex-title {
        float: none;
        padding-right: 10px;
        padding-top: 1px;
    }
    .col-lex-val {
        float: none;
    }
}