.my-item {
    min-height: 1px;
    padding-left: 7px;
    padding-right: 7px;
    position: relative;
    float: left;
    width: 33%;
    text-align: center;
    padding-bottom: 15px;
}
@media(--medium){
    .my-item {
        width: 33.333333% !important;
    }
}
@media(--small){
    .my-item {
        width: 33% !important;
    }
}
@media(--xsmall){
    .my-item {
        width: 50% !important;
    }
}
@media(--vxsmall){
    .my-item {
        width: 100% !important;
    }
}
.my-inner-location-item {
    overflow: hidden;
    position: relative;
    height: 570px;
    text-align: center;
    margin-bottom: 0;
    background: white;
}
@media(--medium){
    .shop {
        & .my-inner-location-item {
            height: 562px;
        }
    }
}
.my-inner-people-item{
    height: auto;
}
.cu1-row {
    margin-left: 7px;
    margin-right: 7px;
}
@media(--xsmall){
    .cu1-row {
        padding: 0 4px;
    }
    .groups {
        & .cu1-row {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
}
.groups {
    & .cu1-row {
        margin-left: -5px;
        margin-right: -5px;
    }
}

.my-inner-location-item .carousel-inner > .item > a > img,
.my-inner-location-item .carousel-inner > .item > img,
.my-inner-location-item .img-responsive, .thumbnail a > img, .thumbnail > img {
    width: 100%;
}
.c-inner-location-text {
    padding: 10px 30px 85px;
    height: 295px !important;
    position: relative;
}
@media(--xsmall){
    .c-inner-location-text {
        padding: 0 20px 85px;
        height: 272px !important;
    }
}
.slider-item-location {
    cursor: pointer;
    height: 260px !important;
}
.my-inner-location-item .item {
    height: 260px;
}
@media(--xsmall){
    .my-inner-location-item .item {
        height: 280px;
    }
}
.my-inner-location-item p {
    margin: 0;
    color: #535353;
    font-size: 1rem;
    line-height: 1.14rem;
}
@media(--xsmall){
    .my-inner-location-item p {
        font-size: 0.9rem;
        line-height: 1rem;
    }
}
.my-inner-location-item {
    font-size: 1rem;
    font-family: work_sansregular;
    line-height: 1.43rem;
    -webkit-box-shadow: 0 0 25px 4px rgba(150,150,150,0.1);
    box-shadow: 0 0 25px 4px rgba(150,150,150,0.1);
}

h4.h4-locations {
    text-transform: uppercase;
    color: #010101;
    font-family: ns_widemedium;
    font-size: 1.14rem;
    margin: 20px 0;
}
@media(--xsmall){
    h4.h4-locations {
        font-size: 0.95rem;
        line-height: 1.2rem;
        margin: 20px 0 10px;
    }
}
.my-inner-friends-item {
    overflow: hidden;
    position: relative;
    height: 420px;
    text-align: center;
    margin-bottom: 0;
    background: white;
}
@media(--xsmall){
    .my-inner-friends-item {
        -webkit-box-shadow: 0 0 3px 3px rgba(100, 100, 100, 0.1);
        box-shadow: 0 0 3px 3px rgba(100, 100, 100, 0.1);
    }
}
.my-inner-groups-item {
    overflow: hidden;
    position: relative;
    height: 380px;
    text-align: center;
    margin-bottom: 0;
    background: white;
}
@media(--xsmall){
    .my-inner-groups-item {
        height: 460px;
    }
}
.c-group {
    display: block;
    padding: 0 25px;
    margin-top: 15px;
}
.c-group-tags {
    height: 40px;
    display: block;
    vertical-align: middle;
    width: 100%;
    font-family: work_sansregular;
    font-size: 1rem;
    color: #535353;
    overflow: hidden;
}
.c-friend {
    display: block;
    width: 100%;
    padding: 20px 25px;
}
@media(--xsmall){
    .c-friend {
        padding: 20px;
    }
}
.friend-name {
    display: block;
    font-family: work_sansmedium;
    font-size: 1.14rem;
    color: #00b9f7 !important;
    text-overflow: ellipsis;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}
@media(--xsmall){
    .friend-name {
        font-size: 0.9rem;
        text-transform: uppercase;
        display: block;
        overflow: hidden;
        height: 35px;
    }
}
.c-group-type {
    margin-left: 20px;
    text-transform: capitalize;
    color: #a9a9a9;
    display: inline-block;
}
@media(--xsmall){
    .c-group-type {
        margin-left: 10px;
    }
}
.btns-group1 {
    bottom: 20px;
    height: 40px;
    left: 20px;
    position: absolute;
    right: 20px;
}
.c-friend-text {
    color: #535353;
    display: block;
    font-family: work_sansregular;
    font-size: 1rem;
    height: 65px;
    vertical-align: middle;
    width: 100%;
}
@media(--xsmall){
    .c-friend-text {
        font-size: 1rem;
        line-height: 1.1rem;
    }
}
.location-map-btn {
    margin-top: 5px;
    position: absolute;
    left: 50%;
    margin-left: -40px;
    z-index: 999;
}
@media(--xsmall){
    .location-map-btn {
        top: 10px;
    }
    .group-ind {
        font-size: 0.8rem;
    }
}
