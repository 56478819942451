.like-item {
    display: inline-block;
}
ul.items-status li a {
    font-family: inherit;
    text-transform: capitalize;
    color: #000000;
    font-size: 1rem;
    text-align: left;
    padding-left: 57px;
    position: relative;
    border: none;
}
ul.items-status li a i{
    position: absolute;
    top: 11px;
    left: 30px;
}
.c-wall-post {
    background: #e3e3e3;
    padding: 20px;
}
@media(--xsmall){
    .c-wall-post {
        padding: 10px 12px;
    }
}
textarea.wall-text-area {
    max-width: 100%;
    min-width: 100%;
    padding: 18px 120px 2px 20px;
    height: 54px;
    min-height: 54px;
    border: none;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    color: #8d8d8d;
    font-size: 1.07rem;
    font-family: work_sansregular;
}
@media(--xsmall){
    textarea.wall-text-area {
        padding: 9px 40px 2px 10px;
        min-height: 35px;
        height: 35px;
        font-size: 1rem;
    }
}
textarea.wall-text-area:focus {
    outline: none;
    box-shadow: none;
}
.c-area-group1 {
    padding: 0 100px;
    position: relative;

    & .form-group {
        margin-bottom: 0;
    }
}
@media(--xsmall){
    .c-area-group1 {
        padding: 0 61px 0 0;
        & .user-image {
            display: none;
        }
        & .bs-s-public {
            line-height: 1.6rem;
            color: #00b9f7;
        }
    }
}
.c-privacy {
    height: 54px;
    width: 54px;
    position: absolute;
    top: 0;
    right: 100px;
}
@media(--xsmall){
    .c-privacy {
        height: 35px;
        width: 35px;
        right: 60px;
    }
}
.btn-should-see {
    display: block;
    position: absolute;
    top: 16px;
    width: 46px;
    text-decoration: none !important;
}
@media(--xsmall){
    .btn-should-see {
        width: 34px;
        top: 7px;
    }
}
.btn-should-see .c-arrow-sel {
    top: 3px;
}
.cu-ui1 {
    top: 25px;
    left: 30px;
}
@media(--xsmall){
    .cu-ui1 {
        top: 25px;
        left: 15px;
    }
}
.wall-text1, .wall-text2 {
    font-size: 1.07rem;
    color: #333333;
    font-family: work_sansregular;
}
@media(--xsmall){
    .wall-text1, .wall-text2 {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    .wall-text1 > strong {
        display: block;
        font-size: 1rem;
        line-height: 1.2rem;
    }
}
.wall-text1 p {
    cursor: pointer;
}
.book-desc1 {
    font-family: work_sanssemibold;
    font-size: 1.14rem;
    color: #1e363e;
    text-decoration: underline;
}
@media(--xsmall){
    .book-desc1 {
        font-size: 1rem;
        line-height: 1.2rem;
    }
}
.book-desc1:hover {
    color: #1e363e;
    text-decoration: none;
}
.public-wall .item-comments {
    border: none !important;
    padding: 10px 0 !important;
}
@media(--xsmall){
    .public-wall .item-comments {
        margin-left: -80px;
        margin-right: -20px;
    }
}
.c-wall-comment {
    background: #f6f6f6;
    border: 1px solid #e3e3e3;
    padding: 1.29rem;
    border-radius: 5px;
    -webkit-border-radius: 5px;
}
@media(--xsmall){
    .c-wall-comment {
        background: #e3e3e3;
        border: none;
        padding: 10px 12px;
        border-radius: 0;
    }
}
.c-area-group2 {
    padding: 0 85px 0 0;
    position: relative;
}
@media(--xsmall){
    .c-area-group2 {
        padding: 0 60px 0 0;
    }
}
.c-area-group2 textarea {
    border: 1px solid #e3e3e3;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    color: #8d8d8d;
    font-family: work_sansregular;
    font-size: 1.07rem;
    height: 40px;
    max-width: 100%;
    min-height: 40px;
    min-width: 100%;
    padding: 10px 15px 2px;
}
@media(--xsmall){
    .c-area-group2 textarea {
        font-size: 1rem;
    }
    .blog.article {
        & .c-area-group2 textarea {
            padding: 10px 5px 2px;
        }
    }
}
.c-people-comment {
    border-bottom: 1px solid #e3e3e3;
    padding: 15px 0;
}
@media(--xsmall){
    .c-people-comment {
        padding: 10px 0;
        font-size: 1rem;
        line-height: 1.2rem;
        margin-left: 20px;
    }
    .blog.article {
        & .c-people-comment {
            margin-left: 0px;
        }
        & .m-btn {
            background: white;
            margin: -20px 15px 15px !important;
            padding: 10px 0px;
            z-index: 9;
            position: relative;
        }
    }
}
.public-wall .item-comments .c-people-comment:last-child {
    border-bottom: none;
}
.people-comment-text {
    margin-left: 50px;
    min-height: 40px;
}
.c-blog .related-item {
    border-bottom: 15px solid #e3e3e3;
    padding: 60px 30px !important;
}
@media(--xsmall){
    .c-blog .related-item {
        padding: 15px !important;
    }
    .c-blog {
        margin: 0 15px 15px 15px;
    }
}
.right-position {
     left: auto !important;
     right: 20px !important;
}
.public-wall .related-item .item-body .verse-block {
    color: #535353;
    font-size: 1.14rem;
    font-family: work_sansregular;
    font-style: italic;
    line-height: 1.29rem;
    padding: 15px;
    background: #f6f6f6;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    margin: 10px 0;
}
@media(--xsmall){
    .public-wall .related-item .item-body .verse-block {
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 10px;
        background-color: #e3e3e3;
        border-color: #a1a1a1;
    }

}
.row.wall {
    & .load-more {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
}
.related-item .item-actions a{
    text-decoration: none;
    margin-right: 10px;
}
@media(--xsmall){
    .related-item .item-actions a{
        margin-right: 40px;
    }
}
.related-item .item-comments{
    padding: 5px 8px;
    border-top: 1px solid #e1e1e8;
}

.related-item-view .verse-block{
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e1e1e8;
}
@media(--medium){
    .related-item .item-actions .popover{
        display: none !important;
    }
}
.wall-actions{
    margin-left: 5px;
}
.wall-actions:hover,.wall-actions:active,.wall-actions:focus{
    text-decoration: none;
}

.blog h2.h4-sub-kit {
    text-decoration: underline;
}

.featured {
    background: #deedef;
}